import orderBy from 'lodash/orderBy'
import config from './config.json'

const yesLabel = 'Yes' // TODO: Is there a better way to do this?
const yesValue = '1'
const noLabel = 'No'
const noValue = '0'

const priorityEnums = [ // TODO: Is there a better way to do this?
  {
    label: 'High',
    value: 'high'
  },
  {
    label: 'Normal',
    value: 'normal'
  },
  {
    label: 'Low',
    value: 'low'
  }
]

// Manage Connector Types
let connectorTypes = [
  {
    label: '-Web API-',
    value: '1'
  },
  {
    label: 'FTP (ftp)',
    value: '11'
  },
  {
    label: 'IMAP (imap-simple)',
    value: '17'
  },
  {
    label: 'SMTP (nodemailer)',
    value: '6'
  },
  {
    label: 'MongoDB (Mongoose)',
    value: '10'
  },
  {
    label: 'Microsoft SQL (mssql)',
    value: '12'
  },
  {
    label: 'LDAP (ldapts)',
    value: '13'
  },
  {
    label: 'PostgreSQL (pg)',
    value: '14'
  },
  {
    label: 'Snowflake (snowflake-sdk)',
    value: '18'
  },
  {
    label: 'SSH2-SFTP (ssh2-sftp-client)',
    value: '16'
  }
]

if (config.customConnectorTypes.oracleDB) {
  connectorTypes.push({
    label: 'OracleDB (oracledb)',
    value: '15'
  })
}

if (config.customConnectorTypes.db2) {
  connectorTypes.push({
    label: 'DB2 iSeries (node-jt400)',
    value: '7'
  })
}

// Finally, sort ConnectorTypes
connectorTypes = orderBy(connectorTypes, ['label'], ['asc'])

const yesNoList = [
  {
    label: yesLabel,
    value: yesValue
  },
  {
    label: noLabel,
    value: noValue
  }
]

const returnConnectorTypeLabel = function (value) {
  let result = ''
  let entry

  for (const x in connectorTypes) {
    entry = connectorTypes[x]

    if (entry.value === value) {
      result = entry.label
      break
    }
  }

  return result
}

export default {
  config,
  version: '7.4.2',
  releaseDate: '8th February 2022',
  connectorTypes,
  returnConnectorTypeLabel,
  yesNoList,
  yesLabel,
  yesValue,
  noLabel,
  noValue,
  priorityEnums
}
